@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');

@layer base {
  @font-face {
    font-family: 'whitehat';
    font-weight: 700;
    font-display: swap;
    src: url('./fonts/SecularOne-Regular.woff2') format('woff2'),
      url('./fonts/SecularOne-Regular.woff') format('woff');
  }
}

html,
body {
  padding: 0;
  margin: 0;
  background-color: #eee9e3;
  z-index: -99;
  font-family: 'Abel', sans-serif;
  font-weight: 300;
}
html {
  scroll-behavior: smooth;
}
body ::-moz-selection {
  /* Code for Firefox */
  color: #d10004;
  background: #ffffff;
}

body ::selection {
  color: #ffffff;
  background: #d10004;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.labelText {
  margin-top: 2rem;
  display: flex;
  color: #282d2e;
  font-size: 2.25rem;
  line-height: 2.5rem;
  flex-direction: column;
  font-family: 'whitehat';
}

.labelText,
span {
  margin-bottom: 1.25rem;
  line-height: 1.7rem;
}

.error {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #7b1c00;
}
